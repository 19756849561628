import { ChevronRight } from '@applift/icons'
import { Link } from '@tanstack/react-router'
import { Box, Button, Typography } from '@applift/factor'

export const PasswordResetSuccess = () => {
  return (
    <>
      <Box>
        <Typography
          component="h1"
          variant="h4"
          weight="demi"
          lineHeight="multi-line"
        >
          Password reset successful
        </Typography>
        <Typography component="span" variant="bodyMedium" gutterBottom={false}>
          Awesome, you've successfully updated your password
        </Typography>
      </Box>
      <Link to="/login">
        <Button endIcon={<ChevronRight />}>Continue Login</Button>
      </Link>
    </>
  )
}

import { Check } from '@applift/icons'
import { Box, Typography, TypographyProps } from '@applift/factor'

export type HintTextProps = TypographyProps & {
  isChecked: boolean
}

export const HintText = (props: HintTextProps) => {
  const { isChecked, ...other } = props
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 4,
        textColor: isChecked ? 'success-500' : 'danger-400',
      }}
    >
      <Check fontSize={18} sx={{ mr: 4 }} />
      <Typography variant="bodySmall" gutterBottom={false} {...other} />
    </Box>
  )
}

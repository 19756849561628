import { QueryClientProvider } from '@tanstack/react-query'
import { CssBaseline, SnackbarProvider } from '@applift/factor'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  AppRootProps,
  DataDogProvider,
  InitProvider,
  InitRootProvider,
} from '@applift/platform'

import { AppRoute } from './AppRoute'
import { queryClient } from '../cache'
import { attachIqmInstance } from '../api'

export const REACT_APP_ORIGIN_URL =
  process.env.REACT_APP_ORIGIN_URL ?? 'https://iqm.stage.applift.com'

/* refer to package.json these envs are configured there */
export const REACT_APP_ENVIRONMENT = (process.env.REACT_APP_ENVIRONMENT ??
  'stage') as AppRootProps['deploymentEnvironment']
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION ?? '-'
export const REACT_APP_DD_CLIENT_TOKEN =
  process.env.REACT_APP_DD_CLIENT_TOKEN ?? ''
export const REACT_APP_DD_APPLICATION_ID =
  process.env.REACT_APP_DD_APPLICATION_ID ?? ''

// We are setting here for quick change that would eliminate restarting the app when ORIGIN_URL changes
const REACT_APP_ORIGIN_URL_LOCALSTORAGE =
  window.localStorage.getItem('ORIGIN_URL')

export const App = () => {
  return (
    <InitRootProvider
      appName="Auth Pages"
      appVersion={REACT_APP_VERSION}
      deploymentEnvironment={REACT_APP_ENVIRONMENT}
      originURLFallback={
        REACT_APP_ORIGIN_URL_LOCALSTORAGE || REACT_APP_ORIGIN_URL
      }
      legacyMode={false}
      attachInterceptorsToInstance={instance => attachIqmInstance(instance)}
      dataDogCredentials={{
        clientToken: REACT_APP_DD_CLIENT_TOKEN,
        applicationId: REACT_APP_DD_APPLICATION_ID,
      }}
      useNewCrossLoginURL={true}
    >
      <DataDogProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider domRoot={document.body}>
            <CssBaseline />
            <InitProvider>
              <AppRoute />
            </InitProvider>
          </SnackbarProvider>
          <ReactQueryDevtools
            initialIsOpen={false}
            panelPosition="right"
            position="bottom-right"
          />
        </QueryClientProvider>
      </DataDogProvider>
    </InitRootProvider>
  )
}

import axios, { AxiosInstance } from 'axios'

let IQMInstance: AxiosInstance

export function attachIqmInstance(instance: AxiosInstance) {
  IQMInstance = instance
}

export function getInstance(): AxiosInstance {
  return IQMInstance || axios
}

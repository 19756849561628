import { AxiosError, AxiosResponse } from 'axios'

import { getInstance } from './instance'
import { WithResponse } from '../models'

interface CreateWorkspaceParams {
  creatorEmail: string
  domain: string
  inviteHash: string
}

export const createWorkspace = async (
  params: CreateWorkspaceParams
): Promise<WithResponse<string>> => {
  try {
    const { creatorEmail, domain, inviteHash } = params
    const res: AxiosResponse<WithResponse<string>> = await getInstance().post(
      '/v3/ua/workspace',
      { creatorEmail, domain, inviteHash }
    )
    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

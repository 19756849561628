import { createFileRoute, redirect } from '@tanstack/react-router'
import { CreateWorkspace } from '../modules'

export const Route = createFileRoute('/create-workspace')({
  component: CreateWorkspace,
  beforeLoad: ({ context }) => {
    document.title = `Create Workspace | ${context.initContext.initMeta.organizationName}`
  },
  loader: ({ location }) => {
    const { email, hash } = location.state
    if (Boolean(email && hash) === false) {
      throw redirect({ to: '/login' })
    }
  },
})

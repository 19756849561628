import { useMutation } from '@tanstack/react-query'

import { login, LoginResponse } from '../api'

interface UseLoginParams {
  onSuccess: (data: LoginResponse) => void
  onError: (msg: string) => void
}

export const useLogin = (params: UseLoginParams) => {
  const { onSuccess, onError } = params
  const mutationData = useMutation({
    mutationKey: ['login'],
    mutationFn: login,
    onSuccess: ({ success, data, errorObjects }) => {
      if (success === true && data) {
        onSuccess(data)
      } else {
        onError(errorObjects?.[0]?.error || 'Something went wrong!')
      }
    },
    onError: (e: any) => {
      onError(e?.errorObjects?.[0]?.error || 'Something went wrong!')
    },
  })
  return mutationData
}

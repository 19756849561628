import React, { SetStateAction, useRef } from 'react'

import { ChevronRight } from '@applift/icons'
import { useNavigate } from '@tanstack/react-router'
import { ORGANIZATION_TYPE, useInitContext } from '@applift/platform'
import { Box, LoadingButton, TextField, Typography } from '@applift/factor'

import { isValidEmail, stringToBase64 } from '../../utils'
import { useValidateEmail } from '../../hooks'
import { ValidateEmailResponse } from '../../api'
import { ENTER_VALID_EMAIL_MESSAGE } from '../../constants'

export interface EmailBlockProps {
  email: string
  setEmail: React.Dispatch<SetStateAction<string>>
  setActiveStep: React.Dispatch<SetStateAction<'email' | 'password'>>
}

const EMAIL_INVALID_MSG = "Sorry, we couldn't recognize this email address"

export const EmailBlock = (props: EmailBlockProps) => {
  const { email = '', setEmail, setActiveStep } = props
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const {
    initMeta: { organizationType, originURL },
  } = useInitContext()

  const navigate = useNavigate({ from: '/login' })

  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && buttonRef.current) {
      e.preventDefault()
      buttonRef.current.click()
    }
  }

  const onSuccess = (
    { isValid, isWorkspaceAllowed } = {} as ValidateEmailResponse
  ) => {
    setLoading(false)
    // If user email is valid, and user have access to the workspace then allow user to enter password
    if (isValid === true && isWorkspaceAllowed === true) {
      setActiveStep('password')
      // If user email is valid, but user doesn't have access to the workspace and the workspace is super workspace then navigate to /enter-workspace, else display error
    } else if (
      isValid === true &&
      organizationType === ORGANIZATION_TYPE.SUPER // Need to confirm this once, This in Older implementation
    ) {
      navigate({
        to: '/enter-workspace',
        search: prev => ({ ...prev, email: stringToBase64(email) }),
      })
    } else {
      setError(EMAIL_INVALID_MSG)
    }
  }

  const onError = (message: string) => {
    setLoading(false)
    setError(message)
  }

  const validateEmailMutation = useValidateEmail({ onSuccess, onError })

  const validateEmail = async () => {
    setLoading(true)
    setError('')
    let workspaceDomain
    try {
      workspaceDomain = new URL(originURL).hostname
    } catch (err) {
      workspaceDomain = ''
    }
    validateEmailMutation.mutate({ email, workspaceDomain })
  }

  React.useEffect(() => {
    setError(() => {
      if (isValidEmail(email) === false && email !== '') {
        return ENTER_VALID_EMAIL_MESSAGE
      }
      return ''
    })
  }, [email])

  return (
    <>
      <Box>
        <Typography
          component="h1"
          variant="h4"
          weight="demi"
          lineHeight="multi-line"
        >
          Let's get you started!
        </Typography>
        <Typography component="span" variant="bodyMedium" gutterBottom={false}>
          Enter your email to sign in
        </Typography>
      </Box>
      <Box sx={{ width: 100 }}>
        <TextField
          // eslint-disable-next-line
          autoFocus
          fullWidth
          name="email"
          autoComplete="on"
          size="large"
          value={email}
          label="Email ID"
          variant="outlinedDash"
          placeholder="Enter your email ID"
          onKeyDown={handleKeyDown}
          InputProps={{ readOnly: loading }}
          onChange={e => setEmail(e.target.value)}
          sx={{ mb: 12 }}
        />
        {error && (
          <Typography
            component="p"
            variant="bodySmall"
            sx={{ textColor: 'danger-400' }}
          >
            {error}
          </Typography>
        )}
      </Box>
      <LoadingButton
        ref={buttonRef}
        loading={loading}
        endIcon={<ChevronRight />}
        disabled={isValidEmail(email) === false}
        onClick={validateEmail}
      >
        Next
      </LoadingButton>
    </>
  )
}

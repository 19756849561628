import { createFileRoute, redirect } from '@tanstack/react-router'

import { redirectIfUserLoggedIn } from '../utils'

export const Route = createFileRoute('/')({
  beforeLoad: ({ context, location }) => {
    // Redirect to default app if user is already logged in
    return redirectIfUserLoggedIn(
      context.initContext.initMeta.originURL,
      location.pathname
    )
  },
  onError: () => {
    throw redirect({ to: '/login' })
  },
})

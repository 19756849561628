import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '../models'

import { getInstance } from './instance'

export interface ResetPasswordParams {
  email: string
  password: string
  inviteHash: string
}

export const resetPassword = async (
  params: ResetPasswordParams
): Promise<WithResponse<string>> => {
  const { email, password, inviteHash } = params
  try {
    const response: AxiosResponse<WithResponse<string>> =
      await getInstance().post('/v3/ua/user/update-password', {
        email,
        password,
        inviteHash,
      })
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

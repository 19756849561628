import React from 'react'

import { Hide, Show } from '@applift/icons'
import { BaseTextFieldProps, IconButton, TextField } from '@applift/factor'

export const PasswordField = (props: BaseTextFieldProps<any>) => {
  const [visible, setVisible] = React.useState(false)
  return (
    <TextField
      // eslint-disable-next-line
      autoFocus
      name="password"
      autoComplete="on"
      fullWidth
      variant="outlinedDash"
      type={visible ? 'text' : 'password'}
      size="large"
      placeholder="Password"
      sx={{ mb: 16 }}
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => setVisible(prev => !prev)} size="large">
            {visible ? <Hide /> : <Show />}
          </IconButton>
        ),
      }}
      {...props}
    />
  )
}

import React from 'react'

import { Box, Typography } from '@applift/factor'

import { HintText } from './HintText'

export interface HintsProps {
  password: string
  setIsStrongPassword: React.Dispatch<React.SetStateAction<boolean>>
}

export const Hints = (props: HintsProps) => {
  const { password, setIsStrongPassword } = props

  const validations = React.useMemo(() => {
    const validations = {
      hasMinimumLength: password.length >= 8,
      hasLowercase: /[a-z]/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSpecialChar: /[!@#$%^&*]/.test(password),
    }
    setIsStrongPassword(Object.values(validations).every(Boolean))
    return validations
  }, [password, setIsStrongPassword])

  return (
    <Box>
      <Typography variant="bodySmall" sx={{ mb: 8 }}>
        Hints for a stronger password
      </Typography>
      <Box sx={{ pl: 8 }}>
        <HintText isChecked={validations.hasMinimumLength}>
          Require at-least 8 characters
        </HintText>
        <HintText isChecked={validations.hasUppercase}>
          Require at-least 1 uppercase letter
        </HintText>
        <HintText isChecked={validations.hasLowercase}>
          Require at-least 1 lowercase letter
        </HintText>
        <HintText isChecked={validations.hasNumber}>
          Require at-least 1 number
        </HintText>
        <HintText isChecked={validations.hasSpecialChar}>
          Require at-least 1 special character
        </HintText>
      </Box>
    </Box>
  )
}

import * as React from 'react'

import { sx as sxFunc, Tab, Tabs, TypoTooltip } from '@applift/factor'

import { LinkType } from '../../models'
import styles from './index.module.scss'

export interface FooterLinksProps {
  /**
   * set the top nav links
   */
  footerLinks?: LinkType[]
}
export const FooterLinks = React.forwardRef<HTMLDivElement, FooterLinksProps>(
  function FooterLinks(props) {
    const { footerLinks } = props
    const renderFooterLinks = footerLinks?.map((navLink, index) => (
      <Tab
        key={index}
        label={
          <TypoTooltip arrow sx={{ display: 'block', width: 100 }}>
            {navLink.label}
          </TypoTooltip>
        }
        href={navLink.url}
        classes={{ root: styles.footerLink }}
      />
    ))
    return (
      <>
        <Tabs
          aria-label="Footer Navigation"
          size="small"
          classes={{
            indicator: sxFunc({ display: 'none' }),
          }}
        >
          {renderFooterLinks}
        </Tabs>
      </>
    )
  }
)
FooterLinks.displayName = 'FooterLinks'

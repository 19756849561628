import { createFileRoute } from '@tanstack/react-router'

import { CrossLogin } from '../modules'
import { base64toString } from '../utils'

export interface OrganizationSwitchSearchParams {
  accessToken?: string
  expiresIn?: number
  refreshToken?: string
  tokenType?: 'bearer'
  scope?: string
  owId?: string
  redirectAppName?: string
}

export const Route = createFileRoute('/cross-login')({
  component: CrossLogin,
  loaderDeps: ({ search }) => search,
  beforeLoad: ({ context }) => {
    document.title = `Login | ${context.initContext.initMeta.organizationName}`
  },
  loader: ({ deps }): { search: OrganizationSwitchSearchParams } => {
    const search = {
      owId: deps.owId,
      expiresIn: Number(deps.expiresIn) || undefined,
      accessToken: base64toString(deps.accessToken),
      refreshToken: base64toString(deps.refreshToken),
      tokenType: deps.tokenType as 'bearer',
      scope: deps.scope,
      redirectAppName: deps.redirectAppName,
    }
    return { search }
  },
  validateSearch: (
    search: Record<string, string>
  ): OrganizationSwitchSearchParams => {
    return {
      owId: search.owId,
      expiresIn: Number(search.expiresIn) || undefined,
      accessToken: search.accessToken,
      refreshToken: search.refreshToken,
      tokenType: search.tokenType as 'bearer',
      scope: search.scope,
      redirectAppName: search.redirectAppName,
    }
  },
})

import React from 'react'

import { ChevronRight, Edit } from '@applift/icons'
import { Link, useSearch } from '@tanstack/react-router'
import { Box, Chip, LoadingButton, Typography } from '@applift/factor'
import {
  AuthorizeUserAPIValidResp,
  useInitContext,
  useSetupAuthDataInLocalstorage,
} from '@applift/platform'

import styles from '../index.module.scss'
import { useLogin } from '../../../hooks'
import { LoginResponse } from '../../../api'
import { EmailBlockProps } from '../EmailBlock'
import { PasswordField } from './PasswordField'
import { DEFAULT_APP } from '../../../constants'
import { stringToBase64 } from '../../../utils'

export interface PasswordBlockProps extends Omit<EmailBlockProps, 'setEmail'> {
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
}

export const PasswordBlock = (props: PasswordBlockProps) => {
  const { password, setPassword, email, setActiveStep } = props
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [authData, setAuthData] = React.useState<AuthorizeUserAPIValidResp>(
    {} as AuthorizeUserAPIValidResp
  )
  const [owId, setOwId] = React.useState<number>(0)

  const { redirectUrl } = useSearch({ from: '/login' })

  const initMeta = useInitContext().initMeta

  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && buttonRef.current) {
      e.preventDefault()
      buttonRef.current.click()
    }
  }

  useSetupAuthDataInLocalstorage({
    authData,
    owId,
    originURL: initMeta.originURL,
    onComplete: ({ isError, owIdIndex }) => {
      if (isError === false) {
        const defaultRedirectUrl = new URL(
          `/${DEFAULT_APP}/u/${owIdIndex}/`,
          initMeta.originURL
        ).href
        // If redirectUrl present in url, then redirect user to that url, else redirect user to default url
        if (redirectUrl) {
          try {
            window.location.replace(new URL(redirectUrl).href)
          } catch {
            window.location.replace(defaultRedirectUrl)
          }
        } else {
          window.location.replace(defaultRedirectUrl)
        }
      }
    },
  })

  const onSuccess = (data: LoginResponse) => {
    setLoading(false)
    setAuthData(data)
    setOwId(data.owId)
  }

  const onError = (message: string) => {
    setLoading(false)
    setError(message)
  }

  const loginMutation = useLogin({ onSuccess, onError })

  const validateCredentials = () => {
    setLoading(true)
    setError('')
    loginMutation.mutate({ email, password })
  }

  const goToEmailStep = () => {
    setPassword('')
    setActiveStep('email')
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
          alignItems: 'start',
        }}
      >
        <Box>
          <Typography
            component="h1"
            variant="h4"
            weight="demi"
            lineHeight="multi-line"
          >
            Login to {initMeta.organizationName}
          </Typography>
          <Typography
            component="span"
            variant="bodyMedium"
            gutterBottom={false}
          >
            Enter your email to sign in
          </Typography>
        </Box>
        <Chip
          label={email}
          clickable
          onClick={goToEmailStep}
          onDelete={goToEmailStep}
          deleteIcon={
            <Edit sx={{ ml: 8, textColor: 'primary-500' }} fontSize={20} />
          }
          sx={{ textWeight: 'normal' }}
          color="secondary"
        />
      </Box>
      <Box sx={{ width: 100 }}>
        <PasswordField
          value={password}
          onChange={e => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        {initMeta.passwordNote && (
          <Typography
            component="p"
            weight="demi"
            sx={{ textColor: 'neutral-500', mb: 12 }}
          >
            Note: {initMeta.passwordNote}
          </Typography>
        )}
        {error && (
          <Typography
            component="p"
            variant="bodySmall"
            sx={{ textColor: 'danger-400' }}
          >
            {error}
          </Typography>
        )}
      </Box>
      <LoadingButton
        loading={loading}
        endIcon={<ChevronRight />}
        disabled={password.length <= 0}
        onClick={validateCredentials}
        ref={buttonRef}
      >
        Login
      </LoadingButton>
      <Link
        to="/forgot-password"
        search={prev => ({ ...prev, email: stringToBase64(email) })}
        className={styles.forgotLink}
      >
        Forgot Password?
      </Link>
    </>
  )
}

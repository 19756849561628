import React from 'react'

import { Box } from '@applift/factor'
import { useLoaderData } from '@tanstack/react-router'
import { ORGANIZATION_TYPE, useInitContext } from '@applift/platform'

import { AuthLayout } from '../../components'

import { PasswordResetSuccess } from './PasswordResetSuccess'
import { CreateNewPasswordBlock } from './CreateNewPasswordBlock'
import { LinkExpiredBlock } from './LinkExpiredBlock'
import styles from './index.module.scss'

export const ResetPassword = () => {
  const { email = '', error = '' } = useLoaderData({ from: '/reset-password' })
  const defaultActiveStep = error ? 'error' : 'create-new-password'

  const [password, setPassword] = React.useState('')
  const [activeStep, setActiveStep] = React.useState<
    'create-new-password' | 'password-reset' | 'error'
  >(defaultActiveStep)

  const { landingPageImage, svgHostURL, organizationType, footerLinks } =
    useInitContext().initMeta

  const ihpImageURL = new URL('/assets/dist/misc/office-people.svg', svgHostURL)
    .href
  const nonIhpImageURL = new URL(
    '/assets/dist/brand/onboarding1Asset2.svg',
    svgHostURL
  ).href

  const bannerImageFallbackUrl =
    organizationType === ORGANIZATION_TYPE.SUPER ? ihpImageURL : nonIhpImageURL

  const bannerImageURL = landingPageImage || bannerImageFallbackUrl

  return (
    <AuthLayout bannerImage={bannerImageURL} footerLinks={footerLinks}>
      <Box className={styles.formContainer}>
        {activeStep === 'create-new-password' && (
          <CreateNewPasswordBlock
            password={password}
            setPassword={setPassword}
            email={email}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 'password-reset' && <PasswordResetSuccess />}
        {activeStep === 'error' && <LinkExpiredBlock error={error} />}
      </Box>
    </AuthLayout>
  )
}

import * as React from 'react'

import { Close, EqualTo } from '@applift/icons'
import {
  Box,
  Drawer,
  IconButton,
  LayoutContainer,
  sx as sxFunc,
  Tab,
  Tabs,
  TypoTooltip,
} from '@applift/factor'

import styles from './index.module.scss'
import { LinkType } from '../../models/Link'

export interface NavLinksProps {
  /**
   * set the top nav links
   */
  topNavLinks?: LinkType[]
  /**
   * Children will get organization logo & name from main appbar component & it will show in the nav drawer in small device.
   */
  children: React.ReactNode
}

export const NavLinks = React.forwardRef<HTMLDivElement, NavLinksProps>(
  function NavLinks(props) {
    const { children, topNavLinks } = props
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const renderNavLinks = topNavLinks?.map((navLink, index) => (
      <Tab
        key={index}
        label={
          <TypoTooltip arrow sx={{ display: 'block', width: 100 }}>
            {navLink.label}
          </TypoTooltip>
        }
        href={navLink.url}
        classes={{ root: styles.navLink }}
      />
    ))
    return (
      <>
        <Tabs
          aria-label="Navigation"
          size="large"
          classes={{
            root: styles.navLinks,
            indicator: sxFunc({ display: 'none' }),
          }}
        >
          {renderNavLinks}
        </Tabs>
        <IconButton
          color="secondary"
          size="large"
          onClick={() => setOpenDrawer(true)}
          className={styles.navDrawerButton}
        >
          <EqualTo />
        </IconButton>
        <Drawer
          open={openDrawer}
          anchor="right"
          onClose={() => setOpenDrawer(false)}
          variant="temporary"
          className={styles.navDrawerRoot}
        >
          <LayoutContainer
            style={{ width: 300 }}
            header={
              <Box className={styles.navDrawerHeader}>
                {children}
                <IconButton
                  color="secondary"
                  size="large"
                  onClick={() => setOpenDrawer(false)}
                  sx={{ flexShrink: 0 }}
                >
                  <Close />
                </IconButton>
              </Box>
            }
          >
            <Tabs
              aria-label="Navigation"
              size="large"
              orientation="vertical"
              classes={{
                root: sxFunc({ width: 100 }),
                indicator: sxFunc({ display: 'none' }),
              }}
            >
              {renderNavLinks}
            </Tabs>
          </LayoutContainer>
        </Drawer>
      </>
    )
  }
)

NavLinks.displayName = 'NavLinks'

import { Box } from '@applift/factor'
import { ORGANIZATION_TYPE, useInitContext } from '@applift/platform'

import styles from './index.module.scss'
import { AuthLayout } from '../../components'
import { CreateWorkspaceBlock } from './CreateWorkspaceBlock'

export const CreateWorkspace = () => {
  const { landingPageImage, svgHostURL, organizationType, footerLinks } =
    useInitContext().initMeta

  const ihpImageURL = new URL('/assets/dist/misc/office-people.svg', svgHostURL)
    .href
  const nonIhpImageURL = new URL(
    '/assets/dist/brand/onboarding1Asset2.svg',
    svgHostURL
  ).href

  const bannerImageFallbackUrl =
    organizationType === ORGANIZATION_TYPE.SUPER ? ihpImageURL : nonIhpImageURL

  const bannerImageURL = landingPageImage || bannerImageFallbackUrl

  return (
    <AuthLayout bannerImage={bannerImageURL} footerLinks={footerLinks}>
      <Box className={styles.formContainer}>
        <CreateWorkspaceBlock />
      </Box>
    </AuthLayout>
  )
}

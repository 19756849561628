import { WithResponse } from '@applift/platform'
import { useMutation } from '@tanstack/react-query'
import { useRouteContext } from '@tanstack/react-router'

import { resetPassword } from '../api'

export interface UseResetPasswordParams {
  onSuccess: () => void
  onError: (message: string) => void
}
export const useResetPassword = (params: UseResetPasswordParams) => {
  const { onSuccess, onError } = params
  const { queryClient } = useRouteContext({ from: '/reset-password' })

  const mutationData = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: resetPassword,
    onSuccess: ({ success, errorObjects }) => {
      if (success === true) {
        onSuccess()
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) =>
            // @ts-ignore
            queryKey?.[0]?.scope === 'validateResetPasswordHash',
        })
      } else {
        const message = errorObjects?.[0]?.error
        if (typeof message === 'string' && message !== '') {
          onError(message)
        } else {
          onError('Something went wrong!')
        }
      }
    },
    onError: (e: WithResponse) => {
      const message = e?.errorObjects?.[0]?.error
      if (typeof message === 'string' && message !== '') {
        onError(message)
      } else {
        onError('Something went wrong!')
      }
    },
  })

  return mutationData
}

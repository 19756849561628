import { createFileRoute } from '@tanstack/react-router'
import { ResetPassword } from '../modules'
import { validateResetPasswordHash } from '../api'

interface ResetPasswordSearchParams {
  hash?: string
}

type LoaderRes =
  | {
      email?: string
      error?: string
    }
  | undefined

export const Route = createFileRoute('/reset-password')({
  component: ResetPassword,
  loaderDeps: ({ search }) => ({ hash: search.hash || '' }),
  beforeLoad: ({ context }) => {
    document.title = `Reset Password | ${context.initContext.initMeta.organizationName}`
  },
  loader: async ({ deps }): Promise<LoaderRes> => {
    const { hash = '' } = deps
    try {
      const { data, success } = await validateResetPasswordHash({
        inviteHash: hash,
      })

      const isValid = success === true && data?.isValid && data.resetEmail
      const isInvalidReasonProvided =
        success === true &&
        data?.invalidReason &&
        typeof data.invalidReason === 'string'

      if (isValid) {
        return { email: data.resetEmail }
      } else if (isInvalidReasonProvided) {
        return { error: data.invalidReason }
      } else {
        return { error: 'Invalid link. Please contact your admin.' }
      }
    } catch (e: any) {
      return { error: 'Invalid link. Please contact your admin.' }
    }
  },
  validateSearch: (
    search: Record<string, string>
  ): ResetPasswordSearchParams => ({
    hash: search.hash || undefined,
  }),

  postSearchFilters: [
    search => ({
      hash: search?.hash?.replace(/\s/g, '+') || undefined,
    }),
  ],
})

import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '../models'
import { getInstance } from './instance'

interface FindMyWorkspaceParams {
  email: string
}

export const findMyWorkspace = async (
  params: FindMyWorkspaceParams
): Promise<WithResponse<string>> => {
  const { email } = params
  try {
    const res: AxiosResponse<WithResponse<string>> = await getInstance().post(
      '/v3/ua/find-my-workspace',
      { email }
    )
    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

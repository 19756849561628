import React from 'react'
import { Typography } from '@applift/factor'
import { AUTOMATIC_REDIRECT_TIMER_IN_SECONDS } from '../../constants'

export interface RedirectTimerProps {
  redirectUrl: string
}

export const RedirectTimer = (props: RedirectTimerProps) => {
  const { redirectUrl } = props

  const [remainingTime, setRemainingTime] = React.useState(
    AUTOMATIC_REDIRECT_TIMER_IN_SECONDS
  )

  React.useEffect(() => {
    const timerId = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(prev => prev - 1)
      } else {
        window.location.href = redirectUrl
      }
    }, 1000)
    return () => clearInterval(timerId)
  }, [remainingTime, setRemainingTime, redirectUrl])

  return (
    <Typography
      component="span"
      variant="label"
      sx={{ mb: 8, textColor: 'neutral-600' }}
    >
      You will be automatically redirected to the login page in{' '}
      <Typography weight="demi">{remainingTime}</Typography> seconds
    </Typography>
  )
}

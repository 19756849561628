import React from 'react'

import { ChevronRight } from '@applift/icons'
import { Box, LoadingButton, TextField, Typography } from '@applift/factor'

import { isValidEmail } from '../../utils'
import { useSendResetPasswordEmail } from '../../hooks'
import { ENTER_VALID_EMAIL_MESSAGE } from '../../constants'

export interface EmailInputBlockProps {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setActiveStep: React.Dispatch<
    React.SetStateAction<'email-input' | 'email-sent'>
  >
}

export const EmailInputBlock = (props: EmailInputBlockProps) => {
  const { setActiveStep, email, setEmail } = props
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && buttonRef.current) {
      e.preventDefault()
      buttonRef.current.click()
    }
  }

  const onSuccess = () => {
    setLoading(false)
    setActiveStep('email-sent')
  }

  const onError = (message: string) => {
    setLoading(false)
    if (message && typeof message === 'string') {
      setError(message)
    } else {
      setError('Something went wrong!')
    }
  }

  const sendResetPasswordEmail = useSendResetPasswordEmail({
    onSuccess,
    onError,
  })

  const sendEmail = () => {
    setLoading(true)
    setError('')
    sendResetPasswordEmail.mutate({ email: email })
  }

  React.useEffect(() => {
    setError(() => {
      if (isValidEmail(email) === false && email !== '') {
        return ENTER_VALID_EMAIL_MESSAGE
      }
      return ''
    })
  }, [email])

  return (
    <>
      <Box>
        <Typography
          component="h1"
          variant="h4"
          weight="demi"
          lineHeight="multi-line"
        >
          Reset your password
        </Typography>
        <Typography component="span" variant="bodyMedium" gutterBottom={false}>
          Enter your registered email
        </Typography>
      </Box>
      <Box sx={{ width: 100 }}>
        <TextField
          // eslint-disable-next-line
          autoFocus
          variant="outlinedDash"
          label="Email ID"
          size="large"
          placeholder="Enter your email ID"
          fullWidth
          name="email"
          autoComplete="on"
          value={email}
          onKeyDown={handleKeyDown}
          sx={{ mb: 16 }}
          onChange={e => setEmail(e.target.value)}
        />
        {error && (
          <Typography
            component="p"
            variant="bodySmall"
            sx={{ textColor: 'danger-400' }}
          >
            {error}
          </Typography>
        )}
      </Box>
      <LoadingButton
        ref={buttonRef}
        loading={loading}
        endIcon={<ChevronRight />}
        disabled={isValidEmail(email) === false}
        onClick={sendEmail}
      >
        Next
      </LoadingButton>
    </>
  )
}

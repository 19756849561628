import React from 'react'
import { Typography } from '@applift/factor'

export interface ResendTimerProps {
  remainingTime: number
  setRemainingTime: React.Dispatch<React.SetStateAction<number>>
}

export const ResendTimer = (props: ResendTimerProps) => {
  const { remainingTime, setRemainingTime } = props

  React.useEffect(() => {
    const timerId = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(prev => prev - 1)
      }
    }, 1000)
    return () => clearInterval(timerId)
  }, [remainingTime, setRemainingTime])

  return (
    remainingTime > 0 && (
      <Typography
        component="span"
        variant="label"
        sx={{ mb: 8, textColor: 'neutral-600' }}
      >
        Resend email in <Typography weight="demi">{remainingTime}</Typography>{' '}
        seconds
      </Typography>
    )
  )
}

import { Box, Typography } from '@applift/factor'

export interface LinkExpiredBlockProps {
  error: string
}

export const LinkExpiredBlock = (props: LinkExpiredBlockProps) => {
  const { error } = props
  return (
    <Box>
      <Typography
        component="h1"
        variant="h4"
        weight="demi"
        lineHeight="multi-line"
        sx={{ mb: 24 }}
      >
        Link Expired
      </Typography>
      <Typography sx={{ textColor: 'danger-400' }}>
        {error || 'Invalid link. Please contact your admin.'}
      </Typography>
    </Box>
  )
}

import { AxiosError, AxiosResponse } from 'axios'

import { getInstance } from './instance'
import { CreatePasswordResponse, WithResponse } from '../models'
import { IQM_PLATFORM_BASIC_AUTH_HEADER } from '@applift/platform'

interface CreatePasswordParams {
  email: string
  password: string
  inviteHash: string
  workspaceDomain: string
}

export const createPassword = async (params: CreatePasswordParams) => {
  const { email, password, inviteHash, workspaceDomain } = params
  try {
    const res: AxiosResponse<WithResponse<CreatePasswordResponse>> =
      await getInstance().post(
        '/v3/ua/sign-up',
        { email, password, inviteHash, workspaceDomain },
        {
          headers: {
            Authorization: IQM_PLATFORM_BASIC_AUTH_HEADER,
            'x-iaa-host': workspaceDomain,
          },
        }
      )

    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

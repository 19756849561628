import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '../models'
import { getInstance } from './instance'

interface ValidateInviteHashParams {
  inviteHash: string
}

export interface ValidateInviteHashResponse {
  isIHPCustomer: boolean
  isExpired?: boolean
  inviteEmail?: string
  invitedToWorkspace?: string
  organizationName?: string
  isNewUser?: boolean
  /**
   * if a workspace was already created with this invite
   */
  workspaceDomain?: string
  /**
   * if user has already created (workspace AND) password with this invite, this flag will be true
   */
  isInviteProcessed?: boolean
  /**
   * if invite is cancelled before invitee tries to use the link (this happens when user get invited from the customer app)
   */
  isInviteCancelled?: boolean
  /**
   * If invite is deleted before invitee tries to use the link (this happens when user get invited from the organization app)
   */
  isInviteDeleted?: boolean
}

export const validateInviteHash = async (params: ValidateInviteHashParams) => {
  try {
    const { inviteHash } = params
    const response: AxiosResponse<WithResponse<ValidateInviteHashResponse>> =
      await getInstance().post('/v3/ua/invite/validate', { inviteHash })

    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

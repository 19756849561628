import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '@applift/platform'

import { getInstance } from './instance'

export interface SendResetPasswordEmailParams {
  email: string
}

export const sendResetPasswordEmail = async (
  params: SendResetPasswordEmailParams
) => {
  const { email } = params
  try {
    const response: AxiosResponse<WithResponse<string>> =
      await getInstance().post('/v3/ua/user/reset-password', {
        email: email,
      })
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

import React from 'react'

import { useRouterState } from '@tanstack/react-router'
import { ChevronRight, Globe, MailClose } from '@applift/icons'
import { Box, Chip, LoadingButton, Typography } from '@applift/factor'

import { Hints } from './Hints'
import { PasswordField } from './PasswordField'
import { useCreatePassword } from '../../../hooks'

export interface CreatePasswordBlockBlockProps {
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
  setActiveStep: React.Dispatch<
    React.SetStateAction<'create-password' | 'password-created'>
  >
}

export const CreatePasswordBlock = (props: CreatePasswordBlockBlockProps) => {
  const { password, setPassword, setActiveStep } = props
  const [isStrongPassword, setIsStrongPassword] = React.useState(false)
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const {
    email = '',
    workspaceDomain = '',
    hash = '',
  } = useRouterState().location.state

  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && buttonRef.current) {
      e.preventDefault()
      buttonRef.current.click()
    }
  }

  const onSuccess = () => {
    setLoading(false)
    setActiveStep('password-created')
  }
  const onError = (message: string) => {
    setLoading(false)
    setError(message)
  }

  const createPasswordMutation = useCreatePassword({ onSuccess, onError })

  const createPassword = () => {
    setLoading(true)
    setError('')
    createPasswordMutation.mutate({
      password,
      workspaceDomain,
      email,
      inviteHash: hash,
    })
  }

  return (
    <>
      <Typography
        component="h1"
        variant="h4"
        weight="demi"
        lineHeight="multi-line"
      >
        Create new password
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: 8,
        }}
      >
        <Chip
          label={email}
          icon={<MailClose />}
          sx={{ textWeight: 'normal' }}
          color="secondary"
        />
        <Chip
          label={workspaceDomain}
          icon={<Globe />}
          sx={{ textWeight: 'normal' }}
          color="secondary"
        />
      </Box>
      <Box sx={{ width: 100 }}>
        <PasswordField
          // eslint-disable-next-line
          autoFocus
          label="Create Password"
          placeholder="Enter new password"
          size="large"
          variant="outlinedDash"
          autoComplete="new-password"
          fullWidth
          name="new-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          sx={{ mb: 16 }}
        />
        {error && (
          <Typography
            variant="bodySmall"
            sx={{ textColor: 'danger-400', mb: 16 }}
          >
            {error}
          </Typography>
        )}
        <Hints password={password} setIsStrongPassword={setIsStrongPassword} />
      </Box>
      <LoadingButton
        ref={buttonRef}
        loading={loading}
        disabled={isStrongPassword === false}
        onClick={createPassword}
        endIcon={<ChevronRight />}
      >
        Next
      </LoadingButton>
    </>
  )
}

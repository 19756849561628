import { Box, Typography } from '@applift/factor'
import { StatusReady } from '@applift/icons'

export const UrlSentBlock = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 100,
      }}
    >
      <StatusReady color="success" fontSize={36} sx={{ mb: 12 }} />
      <Typography
        component="h1"
        variant="h4"
        weight="demi"
        lineHeight="multi-line"
      >
        URLs Email sent
      </Typography>
      <Typography variant="bodyMedium" gutterBottom={false}>
        We have sent you URLs on the associated email.
      </Typography>
      <Typography variant="bodyMedium" gutterBottom={false}>
        Check your email for logging in your workspaces.
      </Typography>
    </Box>
  )
}

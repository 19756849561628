import { WithResponse } from '@applift/platform'
import { createFileRoute, redirect } from '@tanstack/react-router'

import { Invite } from '../modules'
import { validateInviteHash, ValidateInviteHashResponse } from '../api'
import { stringToBase64 } from '../utils'

interface InviteSearchParams {
  hash?: string
}

type ErrorCode = {
  error: string
  reason: 'INVITE_EXPIRED' | 'INVALID_INVITE' | 'UNKNOWN'
}

export const Route = createFileRoute('/invite')({
  component: Invite,
  validateSearch: (search: Record<string, string>): InviteSearchParams => ({
    hash: search.hash || undefined,
  }),
  beforeLoad: ({ context }) => {
    document.title = `Validating invite... | ${context.initContext.initMeta.organizationName}`
  },
  loaderDeps: ({ search }) => ({ hash: search.hash || '' }),
  loader: async ({ deps }): Promise<ErrorCode | undefined> => {
    const { hash } = deps
    if (Boolean(hash) === false) {
      throw redirect({ to: '/login' })
    }
    let result: WithResponse<ValidateInviteHashResponse>
    try {
      const res = await validateInviteHash({ inviteHash: hash })
      result = res
    } catch (e: any) {
      return {
        error: e.errorObjects?.[0]?.error ?? 'Something went wrong!',
        reason: 'UNKNOWN',
      }
    }
    const { success, data, errorObjects } = result
    if (success === false) {
      return {
        error: errorObjects?.[0]?.error || 'Something went wrong!',
        reason: 'UNKNOWN',
      }
    }

    const isInviteLinkExpired = data?.isExpired

    const isInvalidLink =
      data?.isInviteProcessed === true ||
      data?.isInviteCancelled === true ||
      data?.isInviteDeleted === true

    // does workspace / advertiser has any a user created before
    const userExists =
      data?.isNewUser === false && data?.invitedToWorkspace && data?.inviteEmail

    // Is workspace invited or advertiser invited
    const isWorkspaceInvited = data?.isIHPCustomer

    if (isInviteLinkExpired) {
      return {
        error: 'The invitation link is invalid',
        reason: 'INVITE_EXPIRED',
      }
    }
    if (isInvalidLink) {
      return {
        error: 'The invitation link is invalid',
        reason: 'INVALID_INVITE',
      } // Return the error to the Component
    } else if (userExists) {
      window.location.href = `https://${data.invitedToWorkspace}/#/login?email=${stringToBase64(data?.inviteEmail)}`
      // user invited to a newly created workspace with no users
    } else if (isWorkspaceInvited === true) {
      // user invited to workspace and workspace url is already set
      if (data.workspaceDomain) {
        throw redirect({
          to: '/create-password',
          state: prev => ({
            ...prev,
            hash,
            email: data?.inviteEmail,
            workspaceDomain: data.workspaceDomain,
            organizationName: data?.organizationName,
          }),
        })
      }
      // user invited to workspace and but url is not set, create workspace url and then create password
      throw redirect({
        to: '/create-workspace',
        state: {
          hash: hash,
          email: data?.inviteEmail,
          organizationName: data?.organizationName,
        },
      })
      // user invited to existing workspace or advertiser account
    } else if (isWorkspaceInvited === false) {
      throw redirect({
        to: '/create-password',
        state: prev => ({
          ...prev,
          hash,
          email: data?.inviteEmail,
          workspaceDomain: data?.invitedToWorkspace,
          organizationName: data?.organizationName,
        }),
      })
    } else {
      return { error: 'Something went wrong!', reason: 'UNKNOWN' }
    }
  },
})

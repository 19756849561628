import { WithResponse } from '@applift/platform'
import { useMutation } from '@tanstack/react-query'

import { validateEmail, ValidateEmailResponse } from '../api'

interface UseValidateEmailParams {
  onSuccess: (data?: ValidateEmailResponse) => void
  onError: (msg: string) => void
}

export const useValidateEmail = (params: UseValidateEmailParams) => {
  const { onSuccess, onError } = params
  const mutationData = useMutation({
    mutationKey: ['validateEmail'],
    mutationFn: validateEmail,
    onSuccess: ({ success, data, errorObjects }) => {
      if (success === true) {
        onSuccess(data)
      } else {
        onError(errorObjects?.[0]?.error ?? 'Something went wrong!')
      }
    },
    onError: (e: WithResponse) => {
      onError(e?.errorObjects?.[0]?.error || 'Something went wrong!')
    },
  })

  return mutationData
}

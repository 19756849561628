import React from 'react'

import { ChevronRight } from '@applift/icons'
import { useInitContext } from '@applift/platform'
import { Box, LoadingButton, Typography } from '@applift/factor'
import { useNavigate, useRouterState } from '@tanstack/react-router'

import { useCreateWorkspace } from '../../../hooks'
import { WorkspaceUrlInputField } from './WorkspaceUrlInputField'

export const CreateWorkspaceBlock = () => {
  const [workspaceUrl, setWorkspaceUrl] = React.useState('')
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const { workspaceURLSuffix = '' } = useInitContext().initMeta

  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && buttonRef.current) {
      e.preventDefault()
      buttonRef.current.click()
    }
  }

  const {
    email = '',
    hash = '',
    organizationName,
  } = useRouterState().location.state

  const navigate = useNavigate({ from: '/create-workspace' })

  const onSuccess = () => {
    setLoading(false)
    const workspaceDomain = `${workspaceUrl}${workspaceURLSuffix}`
    navigate({
      to: '/create-password',
      state: prev => ({ ...prev, workspaceDomain }),
    })
  }
  const onError = (message: string) => {
    setLoading(false)
    setError(message)
  }

  const createWorkspaceMutation = useCreateWorkspace({ onSuccess, onError })

  const createWorkspace = () => {
    setLoading(true)
    setError('')
    if (email !== '' && hash !== '') {
      const formattedWorkspaceUrl = workspaceUrl.replace(/-$/, '') // Removes hyphen ("-") from the end if present
      setWorkspaceUrl(formattedWorkspaceUrl)
      createWorkspaceMutation.mutate({
        creatorEmail: email,
        domain: workspaceUrl,
        inviteHash: hash,
      })
    } else {
      setError('Invalid invite link')
    }
  }

  return (
    <>
      <Box>
        <Typography
          component="h1"
          variant="h4"
          weight="demi"
          lineHeight="multi-line"
        >
          Claim workspace URL
        </Typography>
        <Typography component="p" variant="bodyMedium" sx={{ mb: 4 }}>
          Create workspace URL
        </Typography>
        <Typography
          component="p"
          variant="bodyMedium"
          weight="demi"
          gutterBottom={false}
        >
          {organizationName}
        </Typography>
      </Box>
      <Box sx={{ width: 100 }}>
        <WorkspaceUrlInputField
          workspaceUrl={workspaceUrl}
          setWorkspaceUrl={setWorkspaceUrl}
          handleKeyDown={handleKeyDown}
        />
        {error && (
          <Typography
            variant="bodySmall"
            sx={{ textColor: 'danger-400', mt: 12 }}
          >
            {error}
          </Typography>
        )}
      </Box>
      <LoadingButton
        ref={buttonRef}
        loading={loading}
        disabled={workspaceUrl === ''}
        endIcon={<ChevronRight />}
        onClick={createWorkspace}
      >
        Next
      </LoadingButton>
    </>
  )
}

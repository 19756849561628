import { AxiosError, AxiosResponse } from 'axios'

import { WithResponse } from '../models'
import { getInstance } from './instance'

interface VerifyWorkspaceParams {
  workspaceDomain: string
  email: string
}
export const validateWorkspace = async (params: VerifyWorkspaceParams) => {
  const { workspaceDomain, email } = params
  try {
    const res: AxiosResponse<WithResponse<undefined>> =
      await getInstance().post('/v3/ua/user/workspace/validate', {
        workspaceDomain,
        email,
      })
    return res.data
  } catch (e: any) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

import { AxiosError, AxiosResponse } from 'axios'

import { isValidEmail } from '../utils'
import { getInstance } from './instance'
import { WithResponse } from '../models'

interface ValidateEmail {
  email: string
  workspaceDomain: string
}

export interface ValidateEmailResponse {
  isValid: boolean
  owId: number
  isWorkspaceAllowed: boolean
}

export const validateEmail = async (params: ValidateEmail) => {
  const { email, workspaceDomain } = params
  if (isValidEmail(email) === false) {
    return Promise.reject('Invalid email')
  }
  try {
    const res: AxiosResponse<WithResponse<ValidateEmailResponse>> =
      await getInstance().post('/v3/ua/user/email/validate', {
        email,
        workspaceDomain,
      })
    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

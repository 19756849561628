import { WithResponse } from '@applift/platform'
import { useMutation } from '@tanstack/react-query'

import { validateWorkspace } from '../api'

interface UseValidateWorkspace {
  onSuccess: () => void
  onError: (message: string) => void
}

export const useValidateWorkspace = (params: UseValidateWorkspace) => {
  const { onSuccess, onError } = params
  const mutationData = useMutation({
    mutationKey: ['validateWorkspace'],
    mutationFn: validateWorkspace,
    onSuccess: ({ success, errorObjects }) => {
      if (success === true) {
        onSuccess()
      } else {
        const message = errorObjects?.[0]?.error
        if (typeof message === 'string' && message !== '') {
          onError(message)
        } else {
          onError('Something went wrong!')
        }
      }
    },
    onError: (e: WithResponse) => {
      const message = e?.errorObjects?.[0]?.error
      if (typeof message === 'string' && message !== '') {
        onError(message)
      } else {
        onError('Something went wrong!')
      }
    },
  })
  return mutationData
}

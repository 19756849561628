import React from 'react'

import { Box } from '@applift/factor'
import { ORGANIZATION_TYPE, useInitContext } from '@applift/platform'

import styles from './index.module.scss'
import { AuthLayout } from '../../components'
import { PasswordCreated } from './PasswordCreated'
import { CreatePasswordBlock } from './CreatePasswordBlock'

export const CreatePassword = () => {
  const [password, setPassword] = React.useState('')
  const [activeStep, setActiveStep] = React.useState<
    'create-password' | 'password-created'
  >('create-password')

  const { landingPageImage, svgHostURL, organizationType, footerLinks } =
    useInitContext().initMeta

  const ihpImageURL = new URL('/assets/dist/misc/office-people.svg', svgHostURL)
    .href
  const nonIhpImageURL = new URL('/assets/dist/brand/group12.svg', svgHostURL)
    .href

  const bannerImageFallbackUrl =
    organizationType === ORGANIZATION_TYPE.SUPER ? ihpImageURL : nonIhpImageURL

  const bannerImageURL = landingPageImage || bannerImageFallbackUrl

  return (
    <AuthLayout bannerImage={bannerImageURL} footerLinks={footerLinks}>
      <Box className={styles.formContainer}>
        {activeStep === 'create-password' && (
          <CreatePasswordBlock
            password={password}
            setPassword={setPassword}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 'password-created' && <PasswordCreated />}
      </Box>
    </AuthLayout>
  )
}

import { AxiosError, AxiosResponse } from 'axios'

import { getInstance } from './instance'
import { WithResponse } from '../models'

interface ValidateResetPasswordHashParams {
  inviteHash: string
}

export interface ValidateResetPasswordHashResponse {
  isValid: boolean
  resetEmail?: string
  invalidReason?: string
}

export const validateResetPasswordHash = async (
  params: ValidateResetPasswordHashParams
) => {
  const { inviteHash } = params
  try {
    const response: AxiosResponse<
      WithResponse<ValidateResetPasswordHashResponse>
    > = await getInstance().post('/v3/ua/user/reset-password/validate', {
      inviteHash,
    })
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

import React from 'react'

import { Box } from '@applift/factor'
import { useLoaderData } from '@tanstack/react-router'
import { ORGANIZATION_TYPE, useInitContext } from '@applift/platform'

import styles from './index.module.scss'
import { AuthLayout } from '../../components'
import { UrlSentBlock } from './UrlSentBlock'
import { FindMyWorkspaceBlock } from './FindMyWorkspaceBlock'

export const FindMyWorkspace = () => {
  const loaderData = useLoaderData({ from: '/find-my-workspace' })

  const [email, setEmail] = React.useState(loaderData.email)
  const [activeStep, setActiveStep] = React.useState<
    'find-my-workspace' | 'url-sent'
  >('find-my-workspace')

  const { landingPageImage, svgHostURL, organizationType, footerLinks } =
    useInitContext().initMeta

  const ihpImageURL = new URL('/assets/dist/misc/office-people.svg', svgHostURL)
    .href
  const nonIhpImageURL = new URL(
    '/assets/dist/brand/onboarding1Asset2.svg',
    svgHostURL
  ).href

  const bannerImageFallbackUrl =
    organizationType === ORGANIZATION_TYPE.SUPER ? ihpImageURL : nonIhpImageURL

  const bannerImageURL = landingPageImage || bannerImageFallbackUrl

  return (
    <AuthLayout bannerImage={bannerImageURL} footerLinks={footerLinks}>
      <Box className={styles.formContainer}>
        {activeStep === 'find-my-workspace' && (
          <FindMyWorkspaceBlock
            email={email}
            setEmail={setEmail}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 'url-sent' && <UrlSentBlock />}
      </Box>
    </AuthLayout>
  )
}

import React from 'react'

import { Box } from '@applift/factor'
import { useLoaderData } from '@tanstack/react-router'
import { ORGANIZATION_TYPE, useInitContext } from '@applift/platform'

import styles from './index.module.scss'
import { EmailBlock } from './EmailBlock'
import { AuthLayout } from '../../components'
import { PasswordBlock } from './PassworldBlock'

export const Login = () => {
  const { email: userEmail = '', step = 'email' } =
    useLoaderData({ from: '/login' }) ?? {}

  const [email, setEmail] = React.useState(userEmail)
  const [password, setPassword] = React.useState('')
  const [activeStep, setActiveStep] = React.useState<'email' | 'password'>(step)

  const { landingPageImage, svgHostURL, organizationType, footerLinks } =
    useInitContext().initMeta

  const ihpImageURL = new URL('/assets/dist/misc/office-people.svg', svgHostURL)
    .href
  const nonIhpImageURL = new URL(
    '/assets/dist/brand/onboarding1Asset2.svg',
    svgHostURL
  ).href

  const bannerImageFallbackUrl =
    organizationType === ORGANIZATION_TYPE.SUPER ? ihpImageURL : nonIhpImageURL

  const bannerImageURL = landingPageImage || bannerImageFallbackUrl

  return (
    <AuthLayout bannerImage={bannerImageURL} footerLinks={footerLinks}>
      <Box className={styles.formContainer}>
        {activeStep === 'email' ? (
          <EmailBlock
            email={email}
            setEmail={setEmail}
            setActiveStep={setActiveStep}
          />
        ) : (
          <PasswordBlock
            password={password}
            setPassword={setPassword}
            setActiveStep={setActiveStep}
            email={email}
          />
        )}
      </Box>
    </AuthLayout>
  )
}

export const base64toString = (base64str?: string): string | undefined => {
  if (base64str === undefined || base64str === null) return undefined
  try {
    const str = atob(base64str)
    return str
  } catch {
    return undefined
  }
}

export const stringToBase64 = (str?: string): string | undefined => {
  if (str === undefined || str === null) return undefined
  try {
    const base64str = btoa(str)
    return base64str
  } catch {
    return undefined
  }
}

import * as React from 'react'

import clsx from 'clsx'

import { Box, ButtonBase, Tooltip, Typography } from '@applift/factor'
import {
  AUTH,
  InitRootProps,
  useInitContext,
  useInitRootContext,
} from '@applift/platform'
import { useRouter } from '@tanstack/react-router'

import styles from './index.module.scss'
import { NavLinks, NavLinksProps } from './NavLinks'

export interface AppBarProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Omit<NavLinksProps, 'children'> {}
export const AppBar = React.forwardRef<HTMLDivElement, AppBarProps>(
  function AppBar(props) {
    const { className, children, ...other } = props

    const ctx = useInitContext()
    const rootCtx = useInitRootContext()

    const router = useRouter()
    const currentPath = router.state.location.pathname
    const auth = AUTH.readAuthDataFromLocalstorageLegacy()

    const isHomeRoute = currentPath === '/login' || currentPath === '/'
    const isCrossLoginRoute = currentPath === '/cross-login'
    const isUserLoggedIn = Boolean(auth?.accessToken)

    const isHeaderVisible = isCrossLoginRoute || (isHomeRoute && isUserLoggedIn)

    if (isHeaderVisible) {
      return null
    }
    // This will render organization logo and name in appbar & nav drawer in small device also.
    return (
      <Box className={clsx(styles.root, className)} {...other}>
        {/* This will render organization logo and name in appbar in full screen */}
        <RenderOrganizationInfo
          appName={rootCtx?.rootProps?.appName}
          showOrganizationName={ctx?.initMeta.showOrganizationName}
          organizationLogo={ctx?.initMeta?.organizationLogo}
          organizationName={ctx?.initMeta?.organizationName}
        />
        <Box className={styles.content}>{children}</Box>
        <Box className={styles.nav}>
          <NavLinks topNavLinks={ctx.initMeta.headerLinks}>
            {/* This will render organization logo and name in appbar & nav drawer in small device  */}
            <RenderOrganizationInfo
              appName={rootCtx?.rootProps?.appName}
              showOrganizationName={ctx?.initMeta.showOrganizationName}
              organizationLogo={ctx?.initMeta?.organizationLogo}
              organizationName={ctx?.initMeta?.organizationName}
            />
          </NavLinks>
        </Box>
      </Box>
    )
  }
)

interface RenderOrganizationInfoProps {
  appName?: InitRootProps['appName']
  showOrganizationName?: boolean
  organizationLogo?: ReturnType<
    typeof useInitContext
  >['initMeta']['organizationLogo']
  organizationName?: ReturnType<
    typeof useInitContext
  >['initMeta']['organizationName']
}

const RenderOrganizationInfo = (props: RenderOrganizationInfoProps) => {
  const { showOrganizationName, organizationLogo, organizationName } = props

  return (
    <Tooltip title={showOrganizationName ? organizationName : ''} arrow>
      <ButtonBase href={'#'} disableRipple className={styles.title}>
        {organizationLogo && (
          <Box Component="span" className={styles.icon}>
            <img src={organizationLogo} alt={organizationName} />
          </Box>
        )}
        {organizationName && showOrganizationName && (
          <Typography
            component="h1"
            variant="h5"
            weight="bold"
            gutterBottom={false}
            noWrap
          >
            {organizationName}
          </Typography>
        )}
      </ButtonBase>
    </Tooltip>
  )
}

AppBar.displayName = 'AppBar'

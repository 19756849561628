import { AxiosError, AxiosResponse } from 'axios'

import { IQM_PLATFORM_BASIC_AUTH_HEADER, WithResponse } from '@applift/platform'

import { getInstance } from './instance'

interface LoginParams {
  email: string
  password: string
}

export interface LoginResponse {
  access_token: string
  refresh_token: string
  scope: string
  token_type: string
  /**
   * Expires in seconds
   */
  expires_in: number
  /**
   * Logged in user's owid
   */
  owId: number
}

export const login = async (params: LoginParams) => {
  const { email, password } = params
  try {
    const res: AxiosResponse<WithResponse<LoginResponse>> =
      await getInstance().post(
        '/v3/ua/login',
        { email, password, grantType: 'password' },
        {
          headers: {
            Authorization: IQM_PLATFORM_BASIC_AUTH_HEADER,
          },
        }
      )
    return res.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

import { createFileRoute } from '@tanstack/react-router'

import { Login } from '../modules'
import { validateEmail } from '../api'
import { base64toString, isValidEmail, redirectIfUserLoggedIn } from '../utils'

export interface LoginSearchParams {
  email?: string
  redirectUrl?: string
}

export const Route = createFileRoute('/login')({
  loaderDeps: ({ search }) => ({
    email: search.email ?? '',
    redirectUrl: search.redirectUrl,
  }),
  beforeLoad: ({ context, location }) => {
    document.title = `Login | ${context.initContext.initMeta.organizationName}`
    // Redirect to default app if user is already logged in
    redirectIfUserLoggedIn(
      context.initContext.initMeta.originURL,
      location.pathname
    )
  },
  loader: async ({
    deps: { email = '' },
    context,
  }): Promise<{ step: 'email' | 'password'; email: string } | undefined> => {
    const emailString = base64toString(email) ?? ''
    try {
      if (isValidEmail(emailString) === true) {
        const workspaceDomain = new URL(context.initContext.initMeta.originURL)
          .hostname
        const data = await validateEmail({
          email: emailString,
          workspaceDomain,
        })
        if (
          data.success === true &&
          data.data?.isValid === true &&
          data.data.isWorkspaceAllowed === true
        ) {
          return { step: 'password', email: emailString }
        }
      }
      return { step: 'email', email: emailString }
    } catch (err) {
      return { step: 'email', email: emailString }
    }
  },
  validateSearch: (search: Record<string, string>): LoginSearchParams => ({
    email: search.email || undefined,
    redirectUrl: search.redirectUrl || undefined,
  }),
  component: Login,
})

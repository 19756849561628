import { createFileRoute } from '@tanstack/react-router'

import { router } from '../App/AppRoute'
import { CreatePassword } from '../modules'

export const Route = createFileRoute('/create-password')({
  component: CreatePassword,
  beforeLoad: ({ context }) => {
    document.title = `Create Password | ${context.initContext.initMeta.organizationName}`
  },
  loader: ({ location }) => {
    const { hash, email, workspaceDomain } = location.state
    if (Boolean(hash && email && workspaceDomain) === false) {
      router.navigate({ to: '/login' })
    }
  },
})

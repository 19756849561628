import * as React from 'react'
import { ThemeProvider } from '@applift/factor'
import { useInitContext, useInitRootContext } from '@applift/platform'
import {
  createHashHistory,
  createRouter,
  RouterProvider,
} from '@tanstack/react-router'

import { queryClient } from '../cache'
import { routeTree } from '../routeTree.gen'

const TanStackRouterDevTools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
        }))
      )

const hashHistory = createHashHistory()

export const router = createRouter({
  routeTree,
  history: hashHistory,
  defaultPreload: 'intent',
  context: undefined!,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
  interface HistoryState {
    email?: string
    hash?: string
    workspaceDomain?: string
    organizationName?: string
  }
}

const ThemeMapper = {
  SUPER: 'blue',
  WORKSPACE: 'green',
  ADVERTISER: 'green',
}

export const AppRoute = () => {
  const initContext = useInitContext()
  const initRootContext = useInitRootContext()
  const orgType = initContext.initMeta.organizationType

  return (
    <ThemeProvider
      variant={ThemeMapper[orgType] as any}
      style={{ height: '100%' }}
    >
      <RouterProvider
        router={router}
        context={{ initContext, initRootContext, queryClient }}
      />
      <React.Suspense>
        <TanStackRouterDevTools router={router} />
      </React.Suspense>
    </ThemeProvider>
  )
}

import * as React from 'react'

import { Link, useLoaderData } from '@tanstack/react-router'

import { ChevronRight, Edit } from '@applift/icons'
import { useNavigate } from '@tanstack/react-router'
import {
  Box,
  Chip,
  LoadingButton,
  TextField,
  Typography,
} from '@applift/factor'

import styles from './index.module.scss'
import { stringToBase64 } from '../../utils'
import { useValidateWorkspace } from '../../hooks'

export const EnterWorkspaceBlock = () => {
  const [workspaceUrl, setWorkspaceUrl] = React.useState('')
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const { email } = useLoaderData({ from: '/enter-workspace' })

  const navigate = useNavigate({ from: '/enter-workspace' })

  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && buttonRef.current) {
      e.preventDefault()
      buttonRef.current.click()
    }
  }

  const workspaceDomain = React.useMemo(
    // Remove https:// or http:// from the URL
    () => workspaceUrl.replace(/^https?:\/\//, ''),
    [workspaceUrl]
  )

  const onSuccess = () => {
    setLoading(false)
    const searchParams = new URLSearchParams({
      email: stringToBase64(email) || '',
    })
    const redirectURl = `https://${workspaceDomain}/#/login?${searchParams.toString()}`
    window.location.replace(redirectURl)
  }

  const onError = (message: string) => {
    setLoading(false)
    setError(message)
  }

  const validateWorkspaceMutation = useValidateWorkspace({ onSuccess, onError })

  const validateWorkspace = () => {
    setLoading(true)
    setError('')
    validateWorkspaceMutation.mutate({ email, workspaceDomain })
  }

  const navigateToLogin = () =>
    navigate({
      to: '/login',
      search: prev => ({ ...prev, email: stringToBase64(email) }),
    })

  return (
    <>
      <Box>
        <Typography
          component="h1"
          variant="h4"
          weight="demi"
          lineHeight="multi-line"
        >
          Enter workspace URL
        </Typography>
        <Typography component="span" variant="bodyMedium" gutterBottom={false}>
          Let us redirect you to your destination!
        </Typography>
      </Box>
      {email && (
        <Chip
          label={email}
          clickable
          onDelete={navigateToLogin}
          onClick={navigateToLogin}
          deleteIcon={
            <Edit sx={{ ml: 8, textColor: 'primary-500' }} fontSize={20} />
          }
          sx={{ textWeight: 'normal' }}
          color="secondary"
        />
      )}
      <Box sx={{ width: 100 }}>
        <TextField
          // eslint-disable-next-line
          autoFocus
          label="Enter workspace URL"
          variant="outlinedDash"
          size="large"
          fullWidth
          value={workspaceUrl}
          onChange={e => setWorkspaceUrl(e.target.value)}
          onKeyDown={handleKeyDown}
          sx={{ mb: 12 }}
        />
        {error && (
          <Typography
            component="p"
            variant="bodySmall"
            sx={{ textColor: 'danger-400' }}
          >
            {error}
          </Typography>
        )}
      </Box>
      <LoadingButton
        ref={buttonRef}
        loading={loading}
        disabled={workspaceUrl.trim().length <= 0}
        onClick={validateWorkspace}
        endIcon={<ChevronRight />}
        sx={{ mb: 12 }}
      >
        Next
      </LoadingButton>
      <Link
        to="/find-my-workspace"
        search={prev => ({ ...prev, email: stringToBase64(email) })}
        className={styles.forgotLink}
      >
        Forgot your URL?
      </Link>
    </>
  )
}

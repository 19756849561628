import { createFileRoute } from '@tanstack/react-router'
import { FindMyWorkspace } from '../modules'
import { base64toString, isValidEmail } from '../utils'

interface FindMyWorkspaceSearchParams {
  email?: string
}

export const Route = createFileRoute('/find-my-workspace')({
  component: FindMyWorkspace,
  beforeLoad: ({ context }) => {
    document.title = `Find my workspace | ${context.initContext.initMeta.organizationName}`
  },
  loaderDeps: ({ search }) => ({ email: search.email || '' }),
  loader: ({ deps }) => {
    const emailString = base64toString(deps.email) ?? ''
    if (isValidEmail(emailString)) {
      return {
        email: emailString,
      }
    }
    return { email: '' }
  },
  validateSearch: (
    search: Record<string, string>
  ): FindMyWorkspaceSearchParams => ({ email: search.email || undefined }),
})

import { DEFAULT_APP } from '../constants'

interface RedirectParams {
  owIdIndex: number
  originUrl: string
  redirectAppName?: string
}

export const prepareRedirectionUrl = (params: RedirectParams) => {
  const { redirectAppName, originUrl, owIdIndex } = params

  const defaultApp = redirectAppName || DEFAULT_APP
  const redirectUrl = new URL(`${defaultApp}/u/${owIdIndex}/#/`, originUrl).href

  return redirectUrl
}

import { useMutation } from '@tanstack/react-query'

import { WithResponse } from '../models'
import { sendResetPasswordEmail } from '../api'

interface UseSendResetPasswordEmailParams {
  onSuccess: (message: string) => void
  onError: (message: string) => void
}

export const useSendResetPasswordEmail = (
  params: UseSendResetPasswordEmailParams
) => {
  const { onSuccess, onError } = params
  const mutationData = useMutation({
    mutationKey: ['sendResetPasswordEmail'],
    mutationFn: sendResetPasswordEmail,
    onSuccess: ({ success, data, errorObjects }) => {
      if (success === true) {
        if (typeof data === 'string' && data !== '') {
          onSuccess(data)
        } else {
          onSuccess('Email with reset password link sent successfully.')
        }
      } else {
        const message = errorObjects?.[0]?.error
        if (typeof message === 'string' && message !== '') {
          onError(message)
        } else {
          onError('Something went wrong!')
        }
      }
    },
    onError: (e: WithResponse) => {
      const message = e?.errorObjects?.[0]?.error
      if (typeof message === 'string' && message !== '') {
        onError(message)
      } else {
        onError('Something went wrong!')
      }
    },
  })
  return mutationData
}

import React from 'react'

import { Hide, Show } from '@applift/icons'
import { BaseTextFieldProps, IconButton, TextField } from '@applift/factor'

export const PasswordField = (props: BaseTextFieldProps<any>) => {
  const [visible, setVisible] = React.useState(false)
  return (
    <TextField
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => setVisible(prev => !prev)} size="large">
            {visible ? <Hide /> : <Show />}
          </IconButton>
        ),
      }}
      {...props}
    />
  )
}

import * as React from 'react'

import { Box, Col, Layout, Paper, Row } from '@applift/factor'

import styles from './index.module.scss'
import { FooterLinks, FooterLinksProps } from './FooterLinks'

export interface AuthLayoutProps extends FooterLinksProps {
  /**
   * bannerImage set the right side banner image
   */
  bannerImage?: string
  /**
   * children set left side content
   */
  children: React.ReactNode
}
export const AuthLayout = React.forwardRef<HTMLDivElement, AuthLayoutProps>(
  function AuthLayout(props) {
    const { children, bannerImage, footerLinks } = props

    return (
      <Layout>
        <Row
          xs={{ gutterSize: 0 }}
          sx={{ width: 100, height: 100, display: 'flex', overflow: 'hidden' }}
        >
          <Col
            xs={12}
            md={12}
            lg={6}
            sx={{
              height: 100,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: 100,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                p: { xs: 0, lg: 32 },
              }}
            >
              <Paper
                elevation={2}
                sx={{
                  maxHeight: 100,
                  display: 'flex',
                  overflow: 'auto',
                }}
                className={styles.container}
              >
                {children}
              </Paper>
            </Box>
            <Box
              sx={{
                p: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }}
            >
              <FooterLinks footerLinks={footerLinks} />
            </Box>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={6}
            sx={{
              height: 100,
              display: { xs: 'none', lg: 'flex' },
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              bgColor: 'primary-50',
            }}
          >
            <img
              src={bannerImage}
              className={styles.bannerImage}
              alt="Banner"
            />
          </Col>
        </Row>
      </Layout>
    )
  }
)
AuthLayout.displayName = 'AuthLayout'

import React from 'react'

import { Box, LoadingButton, Typography } from '@applift/factor'
import { ChevronRight } from '@applift/icons'

import { useSearch } from '@tanstack/react-router'
import { useResetPassword } from '../../../hooks'
import { Hints } from './Hints'
import { PasswordField } from './PasswordField'

export interface CreateNewPasswordBlockProps {
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
  setActiveStep: React.Dispatch<
    React.SetStateAction<'create-new-password' | 'password-reset' | 'error'>
  >
  email: string
}

export const CreateNewPasswordBlock = (props: CreateNewPasswordBlockProps) => {
  const { password, setPassword, setActiveStep, email } = props

  const [isStrongPassword, setIsStrongPassword] = React.useState(false)
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const { hash = '' } = useSearch({ from: '/reset-password' })

  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && buttonRef.current) {
      e.preventDefault()
      buttonRef.current.click()
    }
  }

  const onSuccess = () => {
    setLoading(false)
    setActiveStep('password-reset')
  }

  const onError = (message: string) => {
    setLoading(false)
    setPassword('')
    setError(message)
  }

  const resetPasswordMutation = useResetPassword({ onSuccess, onError })

  const resetPassword = () => {
    setLoading(true)
    setError('')
    resetPasswordMutation.mutate({
      email: email,
      inviteHash: hash,
      password: password,
    })
  }

  return (
    <>
      <Typography
        component="h1"
        variant="h4"
        weight="demi"
        lineHeight="multi-line"
      >
        Create new password
      </Typography>
      <Box sx={{ width: 100 }}>
        <PasswordField
          // eslint-disable-next-line
          autoFocus
          label="Create Password"
          placeholder="Enter new password"
          size="large"
          variant="outlinedDash"
          fullWidth
          name="new-password"
          autoComplete="new-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          sx={{ mb: 12 }}
        />
        {error && (
          <Typography
            component="p"
            variant="bodySmall"
            sx={{ textColor: 'danger-400', mb: 16 }}
          >
            {error}
          </Typography>
        )}
        <Hints password={password} setIsStrongPassword={setIsStrongPassword} />
      </Box>
      <LoadingButton
        ref={buttonRef}
        loading={loading}
        disabled={isStrongPassword === false}
        endIcon={<ChevronRight />}
        onClick={resetPassword}
      >
        Next
      </LoadingButton>
    </>
  )
}

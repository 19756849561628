import { AUTH } from '@applift/platform'
import { redirect } from '@tanstack/react-router'
import { prepareRedirectionUrl } from './prepareRedirectionUrl'

export const redirectIfUserLoggedIn = (originUrl: string, pathname: string) => {
  const auth = AUTH.readAuthDataFromLocalstorageLegacy()
  if (Boolean(auth?.accessToken) === false) {
    // If user is not logged in and already on /login page then do nothing
    if (pathname === '/login') {
      return
    }
    // If user is not logged in navigate to login page
    throw redirect({ to: '/login' })
  } else {
    // If user is logged in redirect to default app
    const url = prepareRedirectionUrl({
      originUrl: originUrl,
      redirectAppName: 'dashboard',
      owIdIndex: 0,
    })
    window.location.href = url
  }
}

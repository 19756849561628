import { WithResponse } from '@applift/platform'
import { useMutation } from '@tanstack/react-query'

import { createPassword } from '../api'
import { CreatePasswordResponse } from '../models'

interface UseCreatePasswordParams {
  onSuccess: () => void
  onError: (msg: string) => void
}
export const useCreatePassword = (params: UseCreatePasswordParams) => {
  const { onSuccess, onError } = params
  const mutationData = useMutation({
    mutationKey: ['createPassword'],
    mutationFn: createPassword,
    onSuccess: ({ success, errorObjects }) => {
      if (success === false) {
        onError(errorObjects?.[0]?.error || 'Something went wrong!')
      } else if (success === true) {
        onSuccess()
      }
    },
    onError: (e: WithResponse<CreatePasswordResponse>) => {
      onError(e.errorObjects?.[0]?.error || 'Something went wrong!')
    },
  })
  return mutationData
}

import React from 'react'

import { Box } from '@applift/factor'
import { ORGANIZATION_TYPE, useInitContext } from '@applift/platform'

import { useLoaderData } from '@tanstack/react-router'
import { AuthLayout } from '../../components'
import { EmailInputBlock } from './EmailInputBlock'
import { EmailSentBlock } from './EmailSentBlock'
import styles from './index.module.scss'

export const ForgotPassword = () => {
  const loaderData = useLoaderData({ from: '/forgot-password' })
  const [email, setEmail] = React.useState(loaderData.email)
  const [activeStep, setActiveStep] = React.useState<
    'email-input' | 'email-sent'
  >('email-input')

  const { landingPageImage, svgHostURL, organizationType, footerLinks } =
    useInitContext().initMeta

  const ihpImageURL = new URL('/assets/dist/misc/email-message.svg', svgHostURL)
    .href
  const nonIhpImageURL = new URL(
    '/assets/dist/brand/onboarding1Asset2.svg',
    svgHostURL
  ).href

  const bannerImageFallbackUrl =
    organizationType === ORGANIZATION_TYPE.SUPER ? ihpImageURL : nonIhpImageURL

  const bannerImageURL = landingPageImage || bannerImageFallbackUrl

  return (
    <AuthLayout bannerImage={bannerImageURL} footerLinks={footerLinks}>
      <Box className={styles.formContainer}>
        {activeStep === 'email-input' && (
          <EmailInputBlock
            email={email}
            setEmail={setEmail}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 'email-sent' && <EmailSentBlock email={email} />}
      </Box>
    </AuthLayout>
  )
}

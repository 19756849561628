import React from 'react'

import { Box, LoadingButton, Typography } from '@applift/factor'

import { ResendTimer } from './ResendTimer'
import { useSendResetPasswordEmail } from '../../hooks'
import { RESEND_EMAIL_TIMER_IN_SECONDS } from '../../constants'

export interface EmailSentBlockProps {
  email: string
}
export const EmailSentBlock = (props: EmailSentBlockProps) => {
  const { email } = props
  const [loading, setLoading] = React.useState(false)

  const [successMessage, setSuccessMessage] = React.useState('')
  const [error, setError] = React.useState('')

  const [remainingTime, setRemainingTime] = React.useState(
    RESEND_EMAIL_TIMER_IN_SECONDS
  )

  const onSuccess = (message: string) => {
    setLoading(false)
    setRemainingTime(RESEND_EMAIL_TIMER_IN_SECONDS)
    setSuccessMessage(message)
  }

  const onError = (message: string) => {
    setLoading(false)
    setError(message)
  }

  const sendResetPasswordEmail = useSendResetPasswordEmail({
    onSuccess,
    onError,
  })

  const resendEmail = () => {
    setLoading(true)
    setError('')
    setSuccessMessage('')
    sendResetPasswordEmail.mutate({ email: email })
  }

  return (
    <>
      <Box>
        <Typography
          component="h1"
          variant="h4"
          weight="demi"
          lineHeight="multi-line"
        >
          Reset your password
        </Typography>
        <Typography variant="bodyMedium">
          We've sent a password reset email, please check your inbox
        </Typography>
        <Typography variant="bodyMedium">
          Haven't received an email? Please check your spam folder, or{' '}
        </Typography>
      </Box>
      <Box
        sx={{
          gap: 12,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          width: 100,
        }}
      >
        <LoadingButton
          loading={loading}
          disabled={remainingTime > 0}
          onClick={resendEmail}
        >
          Resend
        </LoadingButton>
        <ResendTimer
          remainingTime={remainingTime}
          setRemainingTime={setRemainingTime}
        />
        {loading === false && successMessage && (
          <Typography sx={{ textColor: 'success-400' }}>
            {successMessage}
          </Typography>
        )}
        {loading === false && error && (
          <Typography sx={{ textColor: 'danger-400' }}>{error}</Typography>
        )}
      </Box>
    </>
  )
}

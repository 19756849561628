import React from 'react'

import { ChevronRight } from '@applift/icons'
import { Box, LoadingButton, TextField, Typography } from '@applift/factor'

import { isValidEmail } from '../../utils'
import { useFindMyWorkspace } from '../../hooks'
import { ENTER_VALID_EMAIL_MESSAGE } from '../../constants'

export interface FindMyWorkspaceBlockProps {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setActiveStep: React.Dispatch<
    React.SetStateAction<'find-my-workspace' | 'url-sent'>
  >
}

export const FindMyWorkspaceBlock = (props: FindMyWorkspaceBlockProps) => {
  const { email = '', setEmail, setActiveStep } = props
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && buttonRef.current) {
      e.preventDefault()
      buttonRef.current.click()
    }
  }

  const onSuccess = () => {
    setLoading(false)
    setActiveStep('url-sent')
  }

  const onError = (message: string) => {
    setLoading(false)
    setError(message)
  }

  const findMyWorkspaceMutation = useFindMyWorkspace({ onSuccess, onError })

  const findMyWorkspace = () => {
    setLoading(true)
    setError('')
    findMyWorkspaceMutation.mutate({
      email: email,
    })
  }

  React.useEffect(() => {
    setError(() => {
      if (isValidEmail(email) === false && email !== '') {
        return ENTER_VALID_EMAIL_MESSAGE
      }
      return ''
    })
  }, [email])

  return (
    <>
      <Box>
        <Typography
          component="h1"
          variant="h4"
          weight="demi"
          lineHeight="multi-line"
        >
          Find your workspace
        </Typography>
        <Typography component="span" variant="bodyMedium" gutterBottom={false}>
          We will help you find existing workspaces associated with your email
          address
        </Typography>
      </Box>
      <Box sx={{ width: 100 }}>
        <TextField
          // eslint-disable-next-line
          autoFocus
          fullWidth
          size="large"
          value={email}
          label="Email ID"
          name="email"
          autoComplete="on"
          variant="outlinedDash"
          placeholder="Enter your email ID"
          onKeyDown={handleKeyDown}
          sx={{ mb: 12 }}
          onChange={e => setEmail(e.target.value)}
        />
        {error && (
          <Typography variant="bodySmall" sx={{ textColor: 'danger-400' }}>
            {error}
          </Typography>
        )}
      </Box>
      <LoadingButton
        ref={buttonRef}
        loading={loading}
        endIcon={<ChevronRight />}
        disabled={isValidEmail(email) === false}
        onClick={findMyWorkspace}
      >
        Next
      </LoadingButton>
    </>
  )
}

import { createFileRoute } from '@tanstack/react-router'

import { ForgotPassword } from '../modules'
import { base64toString, isValidEmail } from '../utils'

export const Route = createFileRoute('/forgot-password')({
  component: ForgotPassword,
  loaderDeps: ({ search }) => ({ email: search.email || '' }),
  beforeLoad: ({ context }) => {
    document.title = `Forgot Password | ${context.initContext.initMeta.organizationName}`
  },
  loader: ({ deps }) => {
    const emailString = base64toString(deps.email) ?? ''
    if (isValidEmail(emailString)) {
      return {
        email: emailString,
      }
    }
    return { email: '' }
  },
  validateSearch: (search: Record<string, string>): { email?: string } => ({
    email: search.email || undefined,
  }),
})

import { createFileRoute, redirect } from '@tanstack/react-router'

import { EnterWorkspace } from '../modules'
import { base64toString, isValidEmail } from '../utils'

export interface EnterWorkspaceSearchParams {
  email?: string
}

export const Route = createFileRoute('/enter-workspace')({
  component: EnterWorkspace,
  beforeLoad: ({ context }) => {
    document.title = `Enter Workspace | ${context.initContext.initMeta.organizationName}`
  },
  loaderDeps: ({ search }) => ({ email: search.email || '' }),
  loader: ({ deps }) => {
    const emailString = base64toString(deps.email) ?? ''
    if (isValidEmail(emailString)) {
      return {
        email: emailString,
      }
    } else {
      throw redirect({ to: '/login' })
    }
  },
  validateSearch: (
    search: Record<string, string>
  ): EnterWorkspaceSearchParams => ({ email: search.email || undefined }),
})

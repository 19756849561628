import React from 'react'

import { useLoaderData, useNavigate } from '@tanstack/react-router'
import {
  AUTH,
  AuthorizeUserAPIValidResp,
  useInitContext,
  useSetupAuthDataInLocalstorage,
} from '@applift/platform'

import { prepareRedirectionUrl } from '../../utils'

export const CrossLogin = () => {
  {
    const [authData, setAuthData] = React.useState<AuthorizeUserAPIValidResp>(
      {} as AuthorizeUserAPIValidResp
    )
    const [owId, setOwId] = React.useState<number>(0)

    const { search } = useLoaderData({ from: '/cross-login' })
    const navigate = useNavigate()

    const initMeta = useInitContext().initMeta

    useSetupAuthDataInLocalstorage({
      authData,
      owId,
      originURL: initMeta.originURL,
      onComplete: ({ isError, owIdIndex }) => {
        if (isError === false) {
          const redirectUrl = prepareRedirectionUrl({
            originUrl: initMeta.originURL,
            owIdIndex,
            redirectAppName: search.redirectAppName,
          })
          window.location.replace(redirectUrl)
        } else {
          navigate({ to: '/login' })
        }
      },
    })

    React.useEffect(() => {
      console.log({ search })
      if (
        search.accessToken &&
        search.refreshToken &&
        search.owId &&
        search.tokenType &&
        search.scope &&
        search.expiresIn
      ) {
        setAuthData({
          access_token: search.accessToken,
          expires_in: search.expiresIn,
          owId: Number(search.owId),
          refresh_token: search.refreshToken,
          scope: search.scope,
          token_type: search.tokenType,
        })
        setOwId(Number(search.owId))
      } else {
        AUTH.clearAuthTokenFromLocalstorage()
        navigate({ to: '/login' })
      }
    }, [search, navigate])
  }
  return null
}

import { useInitContext } from '@applift/platform'
import { TextField, Typography } from '@applift/factor'

export interface WorkspaceUrlInputFieldProps {
  workspaceUrl: string
  setWorkspaceUrl: React.Dispatch<React.SetStateAction<string>>
  handleKeyDown: (e: React.KeyboardEvent) => void
}

export const WorkspaceUrlInputField = (props: WorkspaceUrlInputFieldProps) => {
  const { workspaceUrl, setWorkspaceUrl, handleKeyDown } = props

  const {
    initMeta: { workspaceURLSuffix = '' },
  } = useInitContext()

  const handleWorkspaceUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const filterValue = value
      .toLowerCase() // All the subsdomains are case insensitive, so converting all to lower case
      .replace(/[^a-z0-9-]/g, '') // Removes non alphanumeric characters except for hyphen
      .replace(/-+/g, '-') // Replace multiple consecutive hyphens to single hyphen
    setWorkspaceUrl(filterValue)
  }

  return (
    <TextField
      // eslint-disable-next-line
      autoFocus
      fullWidth
      size="large"
      value={workspaceUrl}
      label="Workspace URL"
      placeholder="Enter workspace URL"
      variant="outlinedDash"
      onChange={handleWorkspaceUrlChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: workspaceURLSuffix && (
          <Typography component="p" variant="bodySmall" gutterBottom={false}>
            {workspaceURLSuffix}
          </Typography>
        ),
      }}
    />
  )
}

import { LayoutContainer } from '@applift/factor'
import { QueryClient } from '@tanstack/react-query'
import { useDatadogTrackViewsManually } from '@applift/app-utils'
import { useInitContext, useInitRootContext } from '@applift/platform'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'

import { AppBar } from '../components'

type RootContext = {
  initRootContext: ReturnType<typeof useInitRootContext>
  initContext: ReturnType<typeof useInitContext>
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RootContext>()({
  component: RootComponent,
})

function RootComponent() {
  // This hook will track datadog views manually on route change, required for SPA
  useDatadogTrackViewsManually()
  return (
    <>
      <LayoutContainer header={<AppBar />}>
        <Outlet />
      </LayoutContainer>
    </>
  )
}

import { Button, Typography } from '@applift/factor'
import { Link, useRouterState } from '@tanstack/react-router'
import { ChevronRight } from '@applift/icons'
import { RedirectTimer } from './RedirectTimer'

export const PasswordCreated = () => {
  const { organizationName = '', workspaceDomain } =
    useRouterState().location.state

  const redirectUrl = new URL('/#/login', `https://${workspaceDomain}`).href

  return (
    <>
      <Typography
        component="h1"
        variant="h4"
        weight="demi"
        lineHeight="multi-line"
      >
        Password created successfully
      </Typography>
      {organizationName && (
        <Typography
          component="h4"
          variant="bodyLarge"
          lineHeight="multi-line"
          weight="normal"
        >
          Welcome to {organizationName}!
        </Typography>
      )}
      <Link to={redirectUrl}>
        <Button endIcon={<ChevronRight />}>Login</Button>
      </Link>
      <RedirectTimer redirectUrl={redirectUrl} />
    </>
  )
}
